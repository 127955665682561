import React from "react";
import { useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { onsetData } from "../actions/index.js";
// import csv from "csvtojson";

const OnsetForm = () => {
  let fileReader;
  // const state = useSelector((state) => state.onsetData);
  const dispatch = useDispatch();
  const [dat, setDat] = useState({
    units: "nm",
    csv: [],
    l: 650,
    u: 750,
    deg: 4,
    direction: "increasing",
  });
  
  const handleSubmit = (e) => {
    dispatch(onsetData(dat));
    e.preventDefault();
  };
  
  /**
   * Parses CSV content and detects if it has headers
   * @param {string} content - The CSV content as a string
   * @returns {Array} - Array of numerical data
   */
  const parseCSV = (content) => {
    // Split the content by lines
    const lines = content.trim().split('\n');
    
    if (lines.length === 0) {
      return [];
    }
    
    // Check if the first row contains headers (non-numeric values)
    const firstRow = lines[0].split(',');
    const hasHeaders = firstRow.some(item => isNaN(parseFloat(item.trim())));
    
    // Start from index 0 or 1 depending on whether headers exist
    const startIndex = hasHeaders ? 1 : 0;
    
    // Process each line
    const result = [];
    for (let i = startIndex; i < lines.length; i++) {
      if (!lines[i].trim()) continue; // Skip empty lines
      
      const values = lines[i].split(',');
      const numericalRow = [];
      
      // Convert all values to numbers
      let allValid = true;
      for (let j = 0; j < values.length; j++) {
        const numValue = parseFloat(values[j].trim());
        if (isNaN(numValue)) {
          allValid = false;
          break;
        }
        numericalRow.push(numValue);
      }
      
      // Only add rows where all values are valid numbers
      if (allValid && numericalRow.length > 0) {
        result.push(numericalRow);
      }
    }
    
    return result;
  };
  
  const handleFileRead = (e) => {
    try {
      const content = fileReader.result;
      const parsedData = parseCSV(content);
      
      if (parsedData.length === 0) {
        throw new Error("No valid numerical data found in CSV");
      }
      
      setDat({ ...dat, csv: parsedData });
    } catch (err) {
      alert("Error with .csv file: " + err.message);
    }
  };

  const handleFileChosen = (file) => {
    fileReader = new FileReader();
    fileReader.onloadend = handleFileRead;
    fileReader.readAsText(file);
  };

  const handleChangeNum = (e, pos) => {
    setDat({
      ...dat,
      [pos]: parseFloat(e.target.value),
    });
  };
  // useEffect(() => {
  //     let csv = require('../AP25.csv')
  //     // handleFileChosen(csv)
  // }, [])

  return (
    <form
      onSubmit={(e) => {
        handleSubmit(e);
      }}
    >
      <div style={{ display: "flex" }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <label>Lower X Bound:</label>
          <input
            name="l"
            type="text"
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            value={dat.l || ""}
            onChange={(e) => handleChangeNum(e, "l")}
          />
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <label>Upper X Bound:</label>
          <input
            name="eRange"
            type="text"
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            value={dat.u || ""}
            onChange={(e) => handleChangeNum(e, "u")}
          />
        </div>
      </div>
      <br />
      <label>Direction: </label>
      <label>
        <input
          type="radio"
          value="increasing"
          checked={dat.direction === "increasing"}
          onChange={(e) => setDat({ ...dat, direction: e.target.value })}
        />
        increasing
      </label>

      <label>
        <input
          type="radio"
          value="decreasing"
          checked={dat.direction === "decreasing"}
          onChange={(e) => setDat({ ...dat, direction: e.target.value })}
        />
        decreasing
      </label>
      <br />
      <label>Units: </label>
      {/* <label> */}
      {/*   <input */}
      {/*     type="radio" */}
      {/*     value="eV" */}
      {/*     checked={dat.units === "eV"} */}
      {/*     onChange={(e) => setDat({ ...dat, units: e.target.value })} */}
      {/*   /> */}
      {/*   eV */}
      {/* </label> */}

      <label>
        <input
          type="radio"
          value="nm"
          checked={dat.units === "nm"}
          onChange={(e) => setDat({ ...dat, units: e.target.value })}
        />
        nm
      </label>
      <br />
      <label>Polynomial Degree: </label>
      <label>
        <input
          type="radio"
          value="3"
          checked={dat.deg === 3}
          onChange={() => setDat({ ...dat, deg: 3 })}
        />
        3
      </label>

      <label>
        <input
          type="radio"
          value="4"
          checked={dat.deg === 4}
          onChange={() => setDat({ ...dat, deg: 4 })}
        />
        4
      </label>
      <br />
      <input
        type="file"
        name="upload"
        accept=".csv"
        onChange={(e) => handleFileChosen(e.target.files[0])}
      />
      <br />
      <input type="submit" value="Submit" />
    </form>
  );
};

export default OnsetForm;
