import React from "react";
import { useDispatch, useSelector } from "react-redux";
import "./DyeDataListing.css";
import ContactButton from "./ContactButton";
// import {dyeRowIMG} from "./DyeDataListing.js";
// import BandGap from "./BandGap";
import ArtSpec from "./ArtSpec";
import { nextDye, prevDye } from "../actions/index";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import PhotoTable  from "./PhotoTable.js";


const gatherExcitation = (excitations) => {
  var exc = [
    {
      method: "CAM-B3LYP/6-311G(d,p)",
      osci: 0.0,
      HOMO: 0,
      LUMO: 0,
      nm: -1,
    },
    {
      method: "PBE1PBE/6-311G(d,p)",
      osci: 0.0,
      HOMO: 0,
      LUMO: 0,
      nm: -1,
    },
    {
      method: "bhandhlyp/6-311G(d,p)",
      osci: 0.0,
      HOMO: 0,
      LUMO: 0,
      nm: -1,
    },
    {
      method: "LSF",
      osci: 0.0,
      HOMO: 0,
      LUMO: 0,
      nm: -1,
    },
  ];
  for (let i = 0; i < excitations.length; i++) {
    if (excitations[i].exc === 1) {
      const el1 = excitations[i];
      for (let j = 0; j < exc.length; j++) {
        const el2 = exc[j];
        if (el1.method_basis_set === el2.method) {
          exc[j].method = exc[j].method.replace("/", " / ");
          exc[j].nm = parseFloat(el1.nm).toFixed(2);
          exc[j].osci = parseFloat(el1.osci).toFixed(2);
          exc[j].HOMO = parseFloat(el1.HOMO).toFixed(2);
          exc[j].LUMO = parseFloat(el1.LUMO).toFixed(2);
        }
      }
    }
  }
  return exc;
};

const excitationCardHeader = () => {
  return (
    <div className="exc-card-container-big-header">
      <div className="exc-card-container-method">
        <h3>Method</h3>
      </div>
      <div className="vl"></div>
      <div className="exc-card-container">
        <h3>Abs. (nm)</h3>
      </div>
      <div className="vl"></div>
      <div className="exc-card-container">
        <h3>Osci. Str.</h3>
      </div>
      <div className="vl"></div>
      <div className="exc-card-container">
        <h3>HOMO</h3>
      </div>
      <div className="vl"></div>
      <div className="exc-card-container">
        <h3>LUMO</h3>
      </div>
    </div>
  );
};

const excitationCard = (exc) => {
  // console.log("exc:", exc, exc.method)
  var displayName = exc.method;
  if (exc.method === "bhandhlyp / 6-311G(d,p)") {
    displayName = "BHandHLYP / 6-311G(d,p)";
  }
  return (
    <div className="exc-card-container-big">
      <div className="exc-card-container-method">
        <p>{displayName}</p>
      </div>
      <div className="vl"></div>
      <div className="exc-card-container">
        <p>{exc.nm}</p>
      </div>
      <div className="vl"></div>
      <div className="exc-card-container">
        <p>{exc.osci}</p>
      </div>
      <div className="vl"></div>
      <div className="exc-card-container">
        <p>{exc.HOMO}</p>
      </div>
      <div className="vl"></div>
      <div className="exc-card-container">
        <p>{exc.LUMO}</p>
      </div>
    </div>
  );
};

const displayExcitations = (excitations) => {
  const listItems = excitations.map((exc) => (
    <div key={exc.method}>{excitationCard(exc)}</div>
  ));
  return <div>{listItems}</div>;
};

const dyeRow = (mol) => {
  var excitations = gatherExcitation(mol.excitations);
  return (
    <div className="dye-container">
      <h1 style={{ textAlign: "center" }}>{mol.localName}</h1>
      <div>
      <PhotoTable excitations={excitations}/>
      {
          /*
        {excitationCardHeader()}
        {displayExcitations(excitations)}
          */
      }
      </div>
      {/* <div className="center" style={{ height: "100%" }}> */}
      <div>
        {/* <BandGap /> */}
        <ArtSpec dye={mol} coef={[0.07499625, 13.58103743]} />
      </div>
      <div className="center">
        <ContactButton text={"Interested in this structure? "} />
      </div>
    </div>
  );
};

const dyesCardContainer = (mol) => {
  return <div key={mol.localName}>{dyeRow(mol)}</div>;
};

const dyeRows = (molecules) => {
  const n = 10;
  // if (molecules.length > n) {
  //   molecules = molecules.slice(0, n);
  // }
  // for (let i=0; i < molecules.length; i++){
  //     // if (molecules[i].localName == "10ed_26b_10ea"){
  //     if (molecules[i].localName === "5ed_28b_10ea"){
  //         molecules = [molecules[i]]
  //     }
  // }
  if (molecules.length > n) {
    molecules = molecules.slice(0, n);
  }

  const listItems = molecules.map((mol) => (
    <div key={mol.localName}>{dyeRow(mol)}</div>
  ));
  return <div>{listItems}</div>;
};

const DyeListingGraph = () => {
  const dyes = useSelector((state) => state.dyesData);
  const dispatch = useDispatch();
  console.log(dyes.pos);
  return (
    <div>
      {dyes.molecules_active.length > 0 ? (
        <div>
          <h2 style={{ textAlign: "center" }}>
            Use the arrow buttons to navigate through the dyes from the selected
            energy range above.
          </h2>
          {/* {dyeRow(dyes.molecules[0])} */}
          {/*dyeRows(dyes.molecules_active) */}

          <div style={{ margin: 5, textAlign: "center" }}>
            <button onClick={() => dispatch(prevDye())}>
              <FaArrowLeft />
            </button>
            <button onClick={() => dispatch(nextDye())}>
              <FaArrowRight />
            </button>
          </div>
          <div className="center-div">
            {dyesCardContainer(dyes.molecules_active[dyes.pos])}
          </div>
          <div style={{ margin: 5, textAlign: "center" }}>
            <button onClick={() => dispatch(prevDye())}>
              <FaArrowLeft />
            </button>
            <button onClick={() => dispatch(nextDye())}>
              <FaArrowRight />
            </button>
          </div>
        </div>
      ) : (
        <h2 style={{ textAlign: "center" }}>
          No dyes within selected energy region
        </h2>
      )}
    </div>
  );
};

export default DyeListingGraph;
