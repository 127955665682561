import "../App.css"

const DyeCite = () => {
  return (
    <div className="cite-container">
      <p className="note">
      The present data is based on work discussed in the following paper.
      </p>
      <div>
      <p className="citation-p">
        "An automated quantum chemistry-driven, experimental characterization
          for high PCE donor–π–acceptor NIR molecular dyes." T. J. Santaloci,
          W. E. Meador, A. M. Wallace, E. M. Valencia, B. N. Rogers, J. H.
          Delcamp, and R. C. Fortenberry. Digital Discovery,
          2023, 2 (1269-1288) {" "}
        <a
          href={
              "https://doi.org/10.1039/d3dd00023k"
          }
        >
          DOI: 10.1039/d3dd00023k
        </a>
      </p>
      </div>
    </div>
  );
};

export default DyeCite;
