import "../App.css"

const OnsetCite = () => {
  return (
    <div className="cite-container">
      <p className="note">
      The present tool is based on 0nset discussed in the
      following paper. Please cite this paper if you include this
      website's calculated onset value in your work.
      </p>
      <div>
      <p className="citation-p">
        "Accurate Determination of the Onset Wavelength in Optical
        Spectroscopy." A. M. Wallace, C. Curiac, J. H. Delcamp, and R. C.
        Fortenberry. J. Quant. Spectrosc. Rad.Trans., 2021, 265 (107544) {" "}
        <a
          href={
            "https://www.sciencedirect.com/science/article/abs/pii/S0022407321000376"
          }
        >
          DOI: 10.1016/j.jqsrt.2021.107544
        </a>
      </p>
      </div>
    </div>
  );
};

export default OnsetCite;
